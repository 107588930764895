.footer {
  min-height: 70px;
  background-color: #fa3155;
  font-family: "Helvetica Neue";
  color: #fff;
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Horizontally center align */
  align-items: center; /* Vertically center align */
}
.footer p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
}
