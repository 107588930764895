h1 {
  color: #fa3155; /* White text color */
  font-family: "Lemon Mocktail Regular";
}

.profile-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the profile cells */
  gap: 20px; /* Fixed padding between the cells */
  padding: 20px; /* Add some padding around the table */
  margin-bottom: 50px;
}

.profile-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px; /* Maximum width for profile cells */
  padding: 10px;
}

.profile-image-container {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
}

.profile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.profile-text {
  text-align: center;
  margin-top: 10px;
  color: black;
}

.profile-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.profile-description {
  font-size: 16px;
  color: black;
}

@media (min-width: 340px) {
  .profile-cell {
    flex: 0 1 40%;
    min-width: 120px; /* Grow up to 40% of container width */
  }
}

@media (min-width: 460px) {
  .profile-cell {
    flex: 0 1 30%;
    min-width: 170px; /* Grow up to 30% of container width */
  }
}

@media (min-width: 800px) {
  .profile-cell {
    flex: 0 1 30%; /* Grow up to 30% of container width */
  }
}

@media (min-width: 1200px) {
  .profile-cell {
    flex: 0 1 22%; /* Grow up to 22% of container width */
  }
}