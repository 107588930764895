body {
  font-family: "Helvetica Neue", sans-serif;
}

.BeatBlast404 {
  color: #fa3155;
  text-decoration: none;
  font-family: "Lemon Mocktail Regular";
  font-size: 36px;
  font-weight: 500;
  line-height: 39.07px;
  text-align: left;
  padding-left: 20px;
  padding-top: 20px;
}

.not-found-container h1 {
  margin-top: -200px !important;
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.not-found-container h1 {
  font-size: 6em;
  color: #fa3155;
  margin-bottom: 20px;
}

.not-found-container p {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
  max-width: 90%;
}

.home-link {
  font-size: 1.2em;
  color: white;
  background-color: #fa3155;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.home-link:hover {
  background-color: #de2647;
}
