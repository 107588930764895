.button-container {
  position: fixed;
  bottom: 50px;
  right: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
}

.button {
  font-family: "Lemon Mocktail Regular";
  display: inline-block;
  padding: 15px 15px;
  min-width: 260px;
  min-height: 60px;
  background-color: #50cbc4;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 24px;
}
.button svg {
  fill: white;
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
